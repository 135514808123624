import React, {useState, useEffect} from 'react';
import {Card, Form, Container, Button, Alert} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import { Login } from '../actions/SessionAction';

function LoginComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.SessionReducer)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if(!!currentUser && !!currentUser.token){
      navigate('/rfqs')
    }
    if(!!currentUser && !!currentUser.message){
      setError('email or password  not correct')
    }
  }, [currentUser])

  const handleLogin = () => {
    const params = {email, password}
    dispatch(Login(params))
  }

  const handleKeypress = (e) => {
    if(e.key === 'Enter'){
      handleLogin();
    }
  } 

  return (
    <Container className='d-flex align-items-center justify-content-center' style={{height: '90vh'}} >
      <br />
      <br />
      <Card className='w-50'>
        <Card.Header>
          Login
        </Card.Header>
        <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
          <br />
          {error.length !== 0 && <Alert variant='danger'>{error}</Alert>}
          <Form className='w-50'>
            
            <Form.Control type='text' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email'/>
            <br />
            <Form.Control type='password' value={password} onKeyPress={e => handleKeypress(e)} onChange={e => setPassword(e.target.value)} placeholder='Password'/>
            <br />
            <Button onClick={e => handleLogin()}>Login ⟶</Button>
          </Form>
          
          
        </Card.Body>
      </Card>
      <br />
      <br />
    </Container>
  )
}

export default LoginComponent