export const GetUsers = (token) => {
  return async(dispatch) => {
    const users = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'Get',
      headers: {'Content-Type': 'application/json', token: token },
    })

    dispatch({
      type: 'GetUsers', 
      payload: await users.json()
    })
  }
}

export const AddUser = (token, params) => {
  return async(dispatch) => {
    const users = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', token: token},
      body: JSON.stringify({user: params})
    })

    dispatch({
      type: 'AddUser',
      payload: await users.json()
    })
  }
}

export const UpdateUser = (id, token, params) => {
  return async(dispatch) => {
    const users = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', token: token},
      body: JSON.stringify({user: params})
    })

    dispatch({
      type: 'UpdateUser',
      payload: await users.json()
    })
  }
}

export const DeleteUser = (id, token) => {
  return async(dispatch) => {
    const users = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      method: 'DELETE',
      headers: {token: token}
    })

    dispatch({
      type: 'DeleteUser',
      payload: await users.json()
    })
  }
} 