import React, {useState, useEffect} from 'react'
import { Container, Row, Table, Button, ButtonGroup, DropdownButton, Dropdown, Col } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'

import { GetUsers, AddUser, UpdateUser, DeleteUser} from '../../actions/UsersAction';
import { GetCompanies } from '../../actions/CompaniesAction';
import UserModal from './UserModal';
import DeleteModal from '../widgets/modals/DeleteModal'

function UsersIndexComponent() {
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false)
  const [modalType, setModalType] = useState('');
  const [userCurrent, setUserCurrent] = useState({})
  const currentUser = useSelector(state => state.SessionReducer);
  const users = useSelector(state => state.UsersReducer);
  const companies = useSelector(state => state.CompaniesReducer);

  useEffect(() => {
    getData()
  },[])

  const findCompany = (id) => {
    let companyName = '';
    for(let company of companies){
      if(company.id === id){
        companyName = company.name;
        break;
      }
    }
    return(companyName)
  }
  
  const hideModals = () => {
    setDisplayModal(false);
    setModalType('');
    setUserCurrent({});
  }

  const getData = () =>{
    dispatch(GetUsers(currentUser.token));
    dispatch(GetCompanies(currentUser.token));
  }

  const openModals = (type, user) => {
    setModalType(type)
    if(type === 'update' || type === 'delete'){
      setUserCurrent(user)
    }
    
    setDisplayModal(true)
  }

  const handleUser = (user) => {

    if(modalType === 'add'){dispatch(AddUser(currentUser.token, user))}
    if(modalType === 'update'){dispatch(UpdateUser(userCurrent.id, currentUser.token, user))}
    if(modalType === 'delete'){dispatch(DeleteUser(userCurrent.id, currentUser.token))}
    
    hideModals();
  }

  return (
  <Container fluid className="px-5">
      <Row className="my-3 mt-5">
        <Col>
      {currentUser.type === 'admin' && 
        <div>
          <Button onClick={e => openModals('add')}>Add User</Button>
          <br /> 
          <br />
        </div>
      }
      </Col>
      </Row>
      
      <Row className="justify-content-center">
      <Col sm="6">
      <Table size="sm" variant="tight" className="pb-5">
        <thead>
          <tr>
            <th>Company</th>
            <th>E-mail</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user,index) => {return(
            <tr key={index}>
              <td>{findCompany(user.company_id)}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <ButtonGroup size="sm">
                  {currentUser.type === 'admin' &&
                  <Button variant="outline-secondary" onClick={e => openModals('update', user)}><span className="material-symbols-outlined">edit</span></Button>}
                  
                  {currentUser.type === 'admin' &&
                  <Button variant="outline-secondary" onClick={e => openModals('delete', user)}><span className="material-symbols-outlined">delete</span></Button>}
                </ButtonGroup>                
              </td>
            </tr>
          )})}
        </tbody>
      </Table>
      <UserModal showModal={displayModal && (modalType === 'add' || modalType === 'update')} hideModal={hideModals}  handleUser={handleUser} user={userCurrent} companies={companies} type={modalType}/>
      <DeleteModal showModal={displayModal && modalType === 'delete'} confirmModal={handleUser} hideModal={hideModals} element={currentUser} message={`Are you sure you want to delete user ${userCurrent.email} ?`}/>
      </Col>
      </Row>
    </Container>
  )
}

export default UsersIndexComponent