const CompaniesReducer = (state = [], action) => {
  switch(action.type){
    case 'GetCompanies':
      return state = action.payload;
    case 'AddCompany':
      return state = action.payload;
    case 'UpdateCompany':
      return state = action.payload;
    case 'DeleteCompany':
      return state = action.payload; 
    default: 
      return state;
  }
}

export default CompaniesReducer;