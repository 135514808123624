export const GetCells = (params, token) => {
  let searchParams = '?'

  for(let key of Object.keys(params)){
    if(params[key].length !== 0){
      searchParams = searchParams + `q[${key}]=${params[key].replace('%20', ' ')}&`
    }
  }

  return async(dispatch) => {
    const cells = await fetch(`${process.env.REACT_APP_API_URL}/cells${searchParams}`, {
      method: 'Get',
      headers: {'Content-Type': 'application/json', token},
    })

    dispatch({
      type: 'GetCells', 
      payload: await cells.json()
    })
  }
}

export const AddCell = (token, formData) => {

  return async(dispatch) => {
    const cells = await fetch(`${process.env.REACT_APP_API_URL}/cells`, {
      method: 'POST',
      headers: {token: token},
      body: formData
    })

    dispatch({
      type: 'AddCell',
      payload: await cells.json()
    })
  }
}

export const UpdateCell = (id, token, formData) => {

  return async(dispatch) => {
    const cells = await fetch(`${process.env.REACT_APP_API_URL}/cells/${id}`, {
      method: 'PUT',
      headers: {token: token},
      body: formData
    })

    dispatch({
      type: 'UpdateCell',
      payload: await cells.json()
    })
  }
}

export const DeleteCell = (id, token) => {
  return async(dispatch) => {
    const cells = await fetch(`${process.env.REACT_APP_API_URL}/cells/${id}`, {
      method: 'DELETE',
      headers: {token: token}
    })

    dispatch({
      type: 'DeleteCell',
      payload: await cells.json()
    })
  }
} 