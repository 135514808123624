import React from 'react'
import { useNavigate, Link } from 'react-router';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { Logout } from '../../actions/SessionAction';
import LogoDark from './logos/LogoDark';

function NavbarComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.SessionReducer);

  const logout = () => {
    dispatch(Logout());
    navigate('/');
  }

  return (
    <Navbar className='navbar-dark' >
      <Container>
        {window.location.pathname !== '/' && 
          <Navbar.Brand href="/">
            <span >
              <LogoDark height={'40px'}/>
            </span>
          </Navbar.Brand>
        }
        <Nav className="me-auto">
          {currentUser && <Nav.Link onClick={e => navigate('/cells')}>Products</Nav.Link>}
          {currentUser && <Nav.Link onClick={e => navigate('/rfqs')}>Quote requests</Nav.Link>}
          {currentUser && <Nav.Link onClick={e => window.location.href = 'https://ionprocurement.com/terms-of-sale/'}>Terms</Nav.Link>}
          {(currentUser && currentUser.type === 'admin') && <Nav.Link onClick={e => navigate('/users')}>Users</Nav.Link>}
          {(currentUser && currentUser.type === 'admin') && <Nav.Link onClick={e => navigate('/companies')}>Companies</Nav.Link>}
        </Nav>
        <Nav>
          {!currentUser && <Nav.Link className='text-light d-flex align-items-center' onClick={e => navigate('/login')}>Log in <span className="material-symbols-outlined">login</span></Nav.Link>}
          {currentUser && <Nav.Link className='text-light d-flex align-items-center' onClick={e => logout()} ><span className="material-symbols-outlined">logout</span>Log out</Nav.Link>}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default NavbarComponent