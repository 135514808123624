import React from 'react'

function LogoDark({height}) {
  return (
    <svg width="180" height={height} viewBox="0 0 180 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M47.7217 9.04762V3.72549H44.1736L44.1736 9.04762H38.8515V12.5957H44.1736L44.1736 17.9178H47.7217V12.5957H53.0438V9.04762H47.7217Z" fill="#141E28"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M52.5116 28.3847C52.5116 41.7096 41.7096 52.5116 28.3847 52.5116C15.0597 52.5116 4.2577 41.7096 4.2577 28.3847C4.2577 15.0597 15.0597 4.2577 28.3847 4.2577C32.0721 4.2577 35.5664 5.08493 38.692 6.56394H41.8674V3.40035C37.8566 1.23137 33.2644 0 28.3847 0C12.7082 0 0 12.7082 0 28.3847C0 44.0611 12.7082 56.7693 28.3847 56.7693C44.0611 56.7693 56.7693 44.0611 56.7693 28.3847C56.7693 23.5767 55.5739 19.0479 53.4641 15.0793H50.2054V18.0773C51.6844 21.2029 52.5116 24.6972 52.5116 28.3847ZM42.8158 9.0476C44.6769 10.4388 46.3305 12.0924 47.7217 13.9535V12.5957H51.9763C51.133 11.3382 50.1919 10.1519 49.1638 9.0476H47.7217V7.60549C46.6174 6.57739 45.4311 5.63627 44.1736 4.79302V9.0476H42.8158Z" fill="#141E28"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1848 23.1334L23.8556 18.4627L21.3467 15.9538L10.619 26.6815L46.125 26.6815L46.125 23.1334L19.1848 23.1334Z" fill="#141E28"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.5845 33.7069L32.9137 38.3776L35.4226 40.8865L46.1503 30.1588L10.6443 30.1588L10.6443 33.7069L37.5845 33.7069Z" fill="#141E28"/>
      <path d="M73.1796 17.0536C72.572 17.0536 72.0579 16.8433 71.6372 16.4227C71.2166 16.002 71.0062 15.4879 71.0062 14.8802C71.0062 14.2726 71.2166 13.7585 71.6372 13.3378C72.0579 12.9171 72.572 12.7068 73.1796 12.7068C73.7639 12.7068 74.2547 12.9171 74.652 13.3378C75.0726 13.7585 75.2829 14.2726 75.2829 14.8802C75.2829 15.4879 75.0726 16.002 74.652 16.4227C74.2547 16.8433 73.7639 17.0536 73.1796 17.0536ZM74.7221 20.1735V39.3837H71.5321V20.1735H74.7221Z" fill="#141E28"/>
      <path d="M88.537 39.6992C86.7375 39.6992 85.1016 39.2902 83.6293 38.4723C82.1804 37.6543 81.0353 36.4975 80.1939 35.0018C79.376 33.4828 78.967 31.73 78.967 29.7436C78.967 27.7805 79.3877 26.0511 80.229 24.5554C81.0937 23.0364 82.2622 21.8796 83.7345 21.085C85.2068 20.267 86.8544 19.858 88.6773 19.858C90.5001 19.858 92.1477 20.267 93.62 21.085C95.0923 21.8796 96.2491 23.0247 97.0905 24.5204C97.9552 26.0161 98.3875 27.7571 98.3875 29.7436C98.3875 31.73 97.9435 33.4828 97.0554 35.0018C96.1907 36.4975 95.0105 37.6543 93.5149 38.4723C92.0192 39.2902 90.3599 39.6992 88.537 39.6992ZM88.537 36.8948C89.6822 36.8948 90.7572 36.626 91.7621 36.0885C92.767 35.551 93.5733 34.7448 94.1809 33.6697C94.8119 32.5947 95.1274 31.286 95.1274 29.7436C95.1274 28.2012 94.8236 26.8924 94.216 25.8174C93.6083 24.7424 92.8138 23.9478 91.8322 23.4337C90.8507 22.8962 89.7873 22.6274 88.6422 22.6274C87.4737 22.6274 86.3987 22.8962 85.4171 23.4337C84.459 23.9478 83.6878 24.7424 83.1035 25.8174C82.5193 26.8924 82.2271 28.2012 82.2271 29.7436C82.2271 31.3094 82.5076 32.6298 83.0684 33.7048C83.6527 34.7798 84.4239 35.5861 85.3821 36.1236C86.3403 36.6377 87.3919 36.8948 88.537 36.8948Z" fill="#141E28"/>
      <path d="M111.941 19.823C114.278 19.823 116.171 20.5358 117.62 21.9614C119.069 23.3636 119.794 25.3967 119.794 28.0609V39.3837H116.639V28.5166C116.639 26.6003 116.16 25.1397 115.202 24.1348C114.243 23.1065 112.935 22.5923 111.275 22.5923C109.593 22.5923 108.249 23.1182 107.244 24.1698C106.263 25.2215 105.772 26.7522 105.772 28.762V39.3837H102.582V20.1735H105.772V22.9078C106.403 21.9263 107.256 21.1668 108.331 20.6293C109.429 20.0917 110.633 19.823 111.941 19.823Z" fill="#141E28"/>
      <path d="M128.195 23.7141C128.826 22.6157 129.76 21.7043 130.999 20.9798C132.261 20.232 133.722 19.858 135.381 19.858C137.087 19.858 138.629 20.267 140.008 21.085C141.41 21.9029 142.509 23.0597 143.303 24.5554C144.098 26.0277 144.495 27.7454 144.495 29.7085C144.495 31.6482 144.098 33.3776 143.303 34.8967C142.509 36.4157 141.41 37.5959 140.008 38.4372C138.629 39.2785 137.087 39.6992 135.381 39.6992C133.745 39.6992 132.296 39.337 131.034 38.6125C129.796 37.8647 128.849 36.9415 128.195 35.8432V48.498H125.005V20.1735H128.195V23.7141ZM141.235 29.7085C141.235 28.2596 140.943 26.9976 140.359 25.9226C139.775 24.8476 138.98 24.0296 137.975 23.4687C136.994 22.9078 135.907 22.6274 134.715 22.6274C133.546 22.6274 132.46 22.9195 131.455 23.5038C130.473 24.0647 129.679 24.8943 129.071 25.9927C128.487 27.0677 128.195 28.318 128.195 29.7436C128.195 31.1925 128.487 32.4662 129.071 33.5646C129.679 34.6396 130.473 35.4692 131.455 36.0535C132.46 36.6144 133.546 36.8948 134.715 36.8948C135.907 36.8948 136.994 36.6144 137.975 36.0535C138.98 35.4692 139.775 34.6396 140.359 33.5646C140.943 32.4662 141.235 31.1808 141.235 29.7085Z" fill="#141E28"/>
      <path d="M151.884 23.2934C152.445 22.1951 153.24 21.342 154.268 20.7344C155.32 20.1268 156.593 19.823 158.089 19.823V23.1182H157.248C153.672 23.1182 151.884 25.0579 151.884 28.9373V39.3837H148.694V20.1735H151.884V23.2934Z" fill="#141E28"/>
      <path d="M170.15 39.6992C168.35 39.6992 166.714 39.2902 165.242 38.4723C163.793 37.6543 162.648 36.4975 161.806 35.0018C160.989 33.4828 160.58 31.73 160.58 29.7436C160.58 27.7805 161 26.0511 161.842 24.5554C162.706 23.0364 163.875 21.8796 165.347 21.085C166.819 20.267 168.467 19.858 170.29 19.858C172.113 19.858 173.76 20.267 175.233 21.085C176.705 21.8796 177.862 23.0247 178.703 24.5204C179.568 26.0161 180 27.7571 180 29.7436C180 31.73 179.556 33.4828 178.668 35.0018C177.803 36.4975 176.623 37.6543 175.127 38.4723C173.632 39.2902 171.972 39.6992 170.15 39.6992ZM170.15 36.8948C171.295 36.8948 172.37 36.626 173.375 36.0885C174.38 35.551 175.186 34.7448 175.793 33.6697C176.424 32.5947 176.74 31.286 176.74 29.7436C176.74 28.2012 176.436 26.8924 175.828 25.8174C175.221 24.7424 174.426 23.9478 173.445 23.4337C172.463 22.8962 171.4 22.6274 170.255 22.6274C169.086 22.6274 168.011 22.8962 167.03 23.4337C166.072 23.9478 165.3 24.7424 164.716 25.8174C164.132 26.8924 163.84 28.2012 163.84 29.7436C163.84 31.3094 164.12 32.6298 164.681 33.7048C165.265 34.7798 166.036 35.5861 166.995 36.1236C167.953 36.6377 169.004 36.8948 170.15 36.8948Z" fill="#141E28"/>
    </svg>
  )
}

export default LogoDark