import React, {useState, useEffect} from 'react'
import { Modal, Button, Col, Row, Form } from 'react-bootstrap'


function CellModal({showModal, hideModal, handleCell, cell, currentAction}) {
  const [data, setData] = useState({model: '', manufacturer: '', form_factor: '', form_type: 0, depth: 0, width: 0, height: 0,weight: 0, capacity: 0, chemistry: 0, min_voltage: 0, nominal_voltage: 0, max_voltage: 0, c_rating_charge: 0, cycle_life: 0, energy_density: 0, specific_energy: 0, mountingpoints: '', cycle_promise_condition: '', placement: 0, hidden: false,  datasheet: {}, image: {}, documents: [] })
  
  useEffect(() => {
    if(!!cell && showModal && Object.keys(cell).length !== 0 ){
      const cellData = {...cell};
      delete cellData['datasheet_filename'];
      delete cellData['datasheet_url'];
      delete cellData['id'];
      delete cellData['created_at'];
      delete cellData['updated_at'];
      cellData['datasheet'] = {};
      setData({...cellData})
    }
  },[cell, showModal])

  useEffect(() => {    
  },[data])

  const closeModal = () => {
    setData({model: '', manufacturer: '', form_factor: '', form_type: 0, depth: 0, width: 0, height: 0,weight: 0, capacity: 0, chemistry: 0, min_voltage: 0, nominal_voltage: 0, max_voltage: 0, c_rating_charge: 0, cycle_life: 0, energy_density: 0, specific_energy: 0, mountingpoints: '', cycle_promise_condition: '', placement: 0, hidden: false,  datasheet: {}, image: {}, documents: [] })
    hideModal();
  }

  const handleData = (key, value) => {
    const formData = {...data};
    formData[key] = value;
    setData(formData)
   }

  const saveCell = () => {
    const formData = {...data};
    if(formData.documents && formData.documents.length === 0){delete formData['documents']}
    if(!formData.datasheet.type){delete formData['datasheet']}
    if(!formData.image){delete formData['image']}
    console.log(formData.image)
    handleCell(formData)
  }

  const deleteDocument = (index) => {
    const documentData = {...data};
    documentData.documents.splice(index, 1);
    setData(documentData);
  }  

  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentAction} Cell</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Placement</Form.Label>
              <Form.Control value={!!data.placement ? data.placement : ''} onChange={e => handleData('placement', e.target.value)} type='number' />
            </Col>
            <Col>
              <Form.Label>Hidden</Form.Label>
              <Form.Check checked={data.hidden} onChange={e => handleData('hidden',  e.target.checked)}  />
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Label>Model</Form.Label>
              <Form.Control value={!!data.model ? data.model : ''} onChange={e => handleData('model', e.target.value)} type='text' />            
            </Col>
              
            <Col>
              <Form.Label>Manufacturer</Form.Label>
              <Form.Control value={!!data.manufacturer ? data.manufacturer : ''} onChange={e => handleData('manufacturer', e.target.value)} type='text' /> 
            </Col>
          </Row>          
          <Row>
            <Col>
              <Form.Label>Form type</Form.Label>
              {/*<Form.Control value={!!data.form_type ? data.form_type: ''} onChange={e => handleData('form_type', e.target.value)} type='text' />*/}
              <Form.Select value={!!data.form_type ? data.form_type : ''} onChange={e => handleData('form_type', e.target.value)}>
                <option className="text-black-50 " value="" disabled >Select Form Type</option>
                <option value="Prismatic">Prismatic</option>
                <option value="Cylindrical">Cylindrical</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Form factor</Form.Label>
              <Form.Control value={!!data.form_factor ? data.form_factor : ''} disabled={data.form_type==="Prismatic"} onChange={e => handleData('form_factor', e.target.value)} type='text' />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Mounting points</Form.Label>
              <Form.Control value={!!data.mountingpoints ? data.mountingpoints : ''} onChange={e => handleData('mountingpoints', e.target.value)} type='text' />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Depth (mm)</Form.Label>
              <Form.Control value={!!data.depth ? data.depth : ''} onChange={e => handleData('depth', e.target.value)} type='text' />            
            </Col>
            <Col>
              <Form.Label>Width (mm)</Form.Label>
              <Form.Control value={!!data.width ? data.width : ''} onChange={e => handleData('width', e.target.value)} type='text' /> 
            </Col>
            <Col>
              <Form.Label>Height (mm)</Form.Label>
              <Form.Control value={!!data.height ? data.height : ''} onChange={e => handleData('height', e.target.value)} type='text' /> 
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <Form.Label>Weight (g)</Form.Label>
              <Form.Control value={!!data.weight ? data.weight : ''} onChange={e => handleData('weight', e.target.value)} type='text' />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Capacity (Ah)</Form.Label>
              <Form.Control value={!!data.capacity ? data.capacity : ''} onChange={e => handleData('capacity', e.target.value)} /> 
            </Col>
            <Col>
              <Form.Label>Chemistry</Form.Label>
              <Form.Select value={!!data.chemistry ? data.chemistry : ''} onChange={e => handleData('chemistry', e.target.value)} type='select'>
                <option className="text-black-50" value="" disabled>Select chemistry</option>
                <option value="LFP">LFP</option>
                <option value="NMC">NMC</option>
                <option value="NCA">NCA</option>
              </Form.Select> 
            </Col>
          </Row>          
          
          <Row>
            <Col>
              <Form.Label>Minimum voltage (V)</Form.Label>
              <Form.Control value={!!data.min_voltage ? data.min_voltage : ''} onChange={e => handleData('min_voltage', e.target.value)} type='number' />
            </Col>
            <Col>
              <Form.Label>Nominal voltage (V)</Form.Label>
              <Form.Control value={!!data.nominal_voltage ? data.nominal_voltage : ''} onChange={e => handleData('nominal_voltage', e.target.value)} type='number' />
            </Col>
            <Col>
              <Form.Label>Maximum voltage (V)</Form.Label>
              <Form.Control value={!!data.max_voltage ? data.max_voltage : ''} onChange={e => handleData('max_voltage', e.target.value)} type='number' />
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Form.Label>C rating charge</Form.Label>
              <Form.Control value={!!data.c_rating_charge ? data.c_rating_charge : ''} onChange={e => handleData('c_rating_charge', e.target.value)} type='number' />
            </Col>
            <Col>
              <Form.Label>C rating discharge</Form.Label>
              <Form.Control value={!!data.c_rating_discharge ? data.c_rating_discharge : ''} onChange={e => handleData('c_rating_discharge',e.target.value)} type='number' />
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Form.Label>Cycle life</Form.Label>
              <Form.Control value={!!data.cycle_life ? data.cycle_life : ''} onChange={e => handleData('cycle_life', e.target.value)} type='number' />
            </Col>
          </Row>

          {//<Row>
           // <Col>
           //   <Form.Label>Cycle life condition</Form.Label>
            //  <Form.Control value={!!data.cycle_life_condition ? data.cycle_life_condition : ''} onChange={e => handleData('cycle_life_condition', e.target.value)} as='textarea' />
            //</Col>
          //</Row>
           }

          <Row>
            <Col>
              <Form.Label>Cycle promise condition</Form.Label>
              <Form.Control value={!!data.cycle_promise_condition ? data.cycle_promise_condition : ''} onChange={e => handleData('cycle_promise_condition', e.target.value)} as='textarea' />
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Form.Label>Energy density (Wh/L)</Form.Label>
              <Form.Control value={!!data.energy_density ? data.energy_density : ''} onChange={e => handleData('energy_density',e.target.value)} type='number' />
            </Col>
            <Col>
              <Form.Label>Specific energy (Wh/kg)</Form.Label>
              <Form.Control value={!!data.specific_energy ? data.specific_energy : ''} onChange={e => handleData('specific_energy',e.target.value)} type='number' />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Add datasheet</Form.Label>
              <Form.Control onChange={e => handleData('datasheet', e.target.files[0])} type='file' />
            </Col>
            <Col>
              <Form.Label>Add documents</Form.Label>
              <Form.Control onChange={e => handleData('documents', [...data.documents, e.target.files[0]])} type='file' multiple/>
              
              {!!data.documents && data.documents.map((document, index) =>{ return(
                <div key={index} className="d-flex align-items-center justify-content-between">
                  <p>{!!document.filename ? document.filename : document.name}</p>
                  <span className="material-symbols-outlined delete-button" onClick={e => deleteDocument(index)}>close</span>
                </div>  
                
              )})}
            </Col>
            
          </Row>
          <Row>
            <Col>
              <Form.Label>Cell image</Form.Label>
              <Form.Control onChange={e => handleData('image', e.target.files[0])} type='file' multiple/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={e => closeModal()}>
            Cancel
          </Button>
          {<Button onClick={() => saveCell()}>
            {currentAction} Cell
          </Button>}
        </Modal.Footer>
      </Modal>
  )
}

export default CellModal