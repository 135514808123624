import React, {useState} from 'react'
import { Modal, Button, Col, Row, Form, Table } from 'react-bootstrap'

function CellShowModal({showModal, hideModal, cell}) {
  const [chemestries] = useState(['unknown', 'LFP', 'NMC', 'NCA'])
  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title> Cell info </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <th>Model:</th>
                    <td>{cell.model}</td>
                  </tr>
                  <tr>
                    <th>Manufacturer:</th>
                    <td>{cell.manufacturer}</td>
                  </tr>
                  <tr>
                    <th>Chemestry:</th>
                    <td>{cell.chemistry}</td>
                  </tr>
                  <tr>
                    <th>Capacity:</th>
                    <td>{cell.capacity}</td>
                  </tr>
                  <tr>
                    <th>Max voltage:</th>
                    <td>{cell.max_voltage}</td>
                  </tr>
                  <tr>
                    <th>Min voltage:</th>
                    <td> {cell.min_voltage}</td>
                  </tr>
                  <tr>
                    <th>Cycle life:</th>
                    <td>{cell.cycle_life}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className='d-flex align-items-center justify-content-center'>
                <img src={cell.cell_image ? cell.cell_image : ''} alt=""  style={{width: '200px'}} />
                            
            </Col>
          </Row>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          
        </Modal.Footer>
      </Modal>
  
  )
}

export default CellShowModal