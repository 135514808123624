import React, { useEffect, useState } from 'react';
import { Container, Row, Table, Button, ButtonGroup, DropdownButton, Dropdown, Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router';

import RequestQuoteModal from '../rfq/RequestQuoteModal';
import CellModal from './CellModal';
import DeleteModal from '../widgets/modals/DeleteModal';
import CellShowModal from './CellShowModal';
import { GetCells, AddCell, DeleteCell, UpdateCell} from '../../actions/CellsAction';
import { SendRfq } from '../../actions/RfqAction';


function CellIndexComponent() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayRfqModal, setDisplayRfqModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayShowModal, setDisplayShowModal] = useState(false)
  const [currentAction, setCurrentAction] = useState('');
  const [currentCell, setCurrentCell] = useState({});
  const [requestedQuotes, setRequestedQuotes] = useState([]);
  const [searchParams, setSearchParams] = useState({manufacturer_eq: '', form_type_eq: '', chemistry_eq: '', model_i_cont: '', s: 'placement asc'})
  const cells = useSelector(state => state.CellsReducer);
  const currentUser = useSelector(state => state.SessionReducer);

  useEffect(() => {
    getCells();
  }, [])

  const getCells = () => dispatch(GetCells(searchParams, currentUser.token));

  const hideModals = () => {
    setDisplayRfqModal(false);
    setDisplayModal(false);
    setDisplayShowModal(false);
    setCurrentCell({})
  }

  const openRfqModal = (cell) => {
    setCurrentCell(cell)
    setDisplayRfqModal(true)
  }

  const requestQuote = (quote) => {
    let rfq = { cell_id: currentCell.id, company_id: currentUser.company_id, quantity: quote.quantity, can_be_pooled: quote.canBePooled, last_date_for_quotation: quote.lastDateForQuotation, delivery_dates_and_amounts: quote.deliveryDateAndAmmounts, requested_modifications: quote.requestedModifications, delivery_address: quote.deliveryAddress, note: quote.note, status: 10, inco_terms: quote.incoTerms };
    dispatch(SendRfq(rfq, currentUser.token));
    setRequestedQuotes([...requestedQuotes, rfq.cell_id]);
    hideModals();
    navigate("/rfqs");
  }

  const openModal = (action, cell) => {
    setCurrentCell(cell);
    setCurrentAction(action);
    setDisplayModal(true);
  } 

  const handleCell = (params) => {
    if(currentAction === 'add' || currentAction === 'update'){
      const formData = new FormData();

      for(const key of Object.keys(params)){
        if(key === 'documents'){
          for(let i = 0; i < params['documents'].length; i++){
            if(!!params.documents[i].signed_id){
              formData.append(`cell[${key}][]`, params.documents[i].signed_id)
            }else{
              formData.append(`cell[${key}][]`, params.documents[i])
            }
            
          }
        }else{
          formData.append(`cell[${key}]`, params[key])
        } 
      }
      if(currentAction === 'update'){        
        dispatch(UpdateCell(currentCell.id, currentUser.token, formData))}
      if(currentAction === 'add'){dispatch(AddCell(currentUser.token, formData))}
    }
    if(currentAction === 'delete'){
      dispatch(DeleteCell(currentCell.id, currentUser.token));
    }
    hideModals();
    getCells();
  }

  const setSearch = (key, value) => {
    const params = {...searchParams}
    params[key] = value;
    setSearchParams(params)
  }

  const resetSearch = () => {
    setSearchParams({manufacturer_eq: '', chemistry_eq: '', form_type_eq: '', s: 'placement asc'})
    dispatch(GetCells({manufacturer_eq: '', chemistry_eq: '', form_type_eq: '', s: 'placement asc'}, currentUser.token))
  }

  const pointer = (element) => searchParams['s'] === `${element} asc` ? <span className="material-symbols-outlined"> arrow_drop_down </span> : <span className="material-symbols-outlined"> arrow_drop_up </span>

  const setOrder= (element) => {
    let params = {...searchParams}
    if(searchParams['s'].includes(element)){
      if(searchParams['s'].includes('desc')){
        params['s'] = `${element} asc`;
      }else{
        params['s'] = `${element} desc`;
      }
    }else{
      params['s'] = `${element} asc`;
    }
    setSearchParams(params);
    dispatch(GetCells(params, currentUser.token));
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){getCells()}
  }

  const openShow = (cell) => {
    setCurrentCell(cell);
    setDisplayShowModal(true)
  }

  return (
    <Container fluid className="px-5">
      <Container>
        <div className='d-flex mt-5 align-items-center'>
          <div >
            <Form.Label>Manufacturer</Form.Label>
            <Form.Select value={searchParams['manufacturer_eq']} onChange={e => setSearch('manufacturer_eq', e.target.value)}>
              <option value="">Select</option>
              <option value="EVE">EVE</option>
              <option value="Gotion">Gotion</option>
              <option value="Haidi">Haidi</option>
              <option value="Lishen">Lishen</option>
            </Form.Select>
          </div>
          <div className='ml'>
            <Form.Label>Form Type</Form.Label>
            <Form.Select value={searchParams['form_type_eq']} onChange={e => setSearch('form_type_eq', e.target.value)}>
              <option value="">Select</option>
              <option value="10">Prismatic</option>
              <option value="20">Cylindrical</option>
            </Form.Select>
          </div>
          <div className='ml'>
            <Form.Label>Cathode</Form.Label>
            <Form.Select value={searchParams['chemistry_eq']} onChange={e => setSearch('chemistry_eq', e.target.value)}>
            <option className="text-black-50" value="" disabled>Select</option>
                <option value="1">LFP</option>
                <option value="2">NMC</option>
                <option value="3">NCA</option>
            </Form.Select>
          </div>
          <div className="ml">
            <Form.Label>Model</Form.Label>
            <Form.Control value={searchParams['model_eq']} onChange={e => setSearch('model_i_cont', e.target.value)} onKeyPress={e => handleKeyPress(e)}/>
          </div>
          <div className='d-flex justify-content-evenly' style={{height: '36px', width: '230px',  marginTop: '30px'}}>
            <Button onClick={e => getCells()}>Search</Button>
            <Button variant='danger' onClick={e => resetSearch()}>Clear</Button>
          </div>
        </div>
        
        
      </Container>
      <Row className="my-3 mt-5">
        <Col>        
          {currentUser.type === 'admin' &&
            <div>
              <Button onClick={e => openModal('add', {})}>Add Cell</Button>
              <br />
              <br />
            </div>
          }
        </Col>
      </Row>
      <Row>
        <Table size="sm" variant='tight' className='table-bordered table-striped' >
          <thead>
            <tr >
              {currentUser.type === 'admin' && <th className='small text-center'>Placement</th>}
              <th className='small text-center'>Model</th>
              <th className='pointer small text-center' onClick={e => setOrder('manufacturer')}>Manufacturer {pointer('manufacturer')}</th>
              <th className='small text-center'> Type</th>
              <th className='pointer small text-center' onClick={e => setOrder('capacity')}><p className='d-flex'>Capacity <p className=" text-black-50">(Ah)</p>{pointer('capacity')}</p></th>
              <th className='small text-center'>Form factor</th>
              {currentUser.type === 'admin' && <th className='small text-center'>Mounting points</th>}
              <th className='pointer small text-center' onClick={e => setOrder('depth')}> <p className='d-flex'>D <p className="text-black-50"> (mm)</p> {pointer('depth')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('width')}><p className='d-flex'>W <p className="text-black-50"> (mm)</p> {pointer('width')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('height')}><p className='d-flex'> H <p className="text-black-50"> (mm)</p> {pointer('height')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('weight')}><p className='d-flex'>Weight <p className=" text-black-50">(g)</p>{pointer('weight')}</p></th>
             
              <th className='small text-center'>Cathode</th>
              <th className='pointer small text-center' onClick={e => setOrder('min_voltage')}><p className='d-flex'>Min. <p className=" text-black-50">(V)</p>{pointer('min_voltage')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('nominal_voltage')}><p className='d-flex'>Nom. <p className=" text-black-50">(V)</p>{pointer('nominal_voltage')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('max_voltage')}><p className='d-flex'>Max. <p className=" text-black-50">(V)</p>{pointer('max_voltage')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('c_rating_charge')}><p className='d-flex'>Max. chg <p className=" text-black-50">(C)</p> {pointer('c_rating_charge')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('c_rating_discharge')}><p className='d-flex'>Max. dchg <p className=" text-black-50">(C)</p>{pointer('c_rating_discharge')}</p></th>
              {currentUser.type === 'admin' && <th className='pointer small text-center' onClick={e => setOrder('cycle_life')}><p className='d-flex'>Cycle life {pointer('cycle_life')}</p></th>}
              { currentUser.type === 'admin' && <th className='small text-center'>Cycle condition</th>}
              <th className='pointer small text-center' onClick={e => setOrder('energy_density')}><p className='d-flex'>Wh/Kg {pointer('energy_density')}</p></th>
              <th className='pointer small text-center' onClick={e => setOrder('specific_energy')}><p className='d-flex'>Wh/L {pointer('specific_energy')}</p></th>
              <th className='small text-center'>Docs.</th>
              <th className='small text-center'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cells && cells.map((cell, index) => {
              return (
                <tr key={index} style={cell.hidden && currentUser.type !== 'admin' ? {display: 'none'} : {display: 'table-row'}}>
                  {currentUser.type === 'admin' && <td className='text-center'>{cell.placement}</td>}
                  <td className='text-center pointer' onClick={e => openShow(cell)}>{cell.model}</td>
                  <td className='text-center'>{cell.manufacturer}</td>
                  <td className='text-center'>{cell.form_type}</td>
                  <td className='text-center'>{cell.capacity}</td>
                  <td className='text-center'>{cell.form_factor}</td>
                  {currentUser.type === 'admin' && <td className='text-center'>{cell.mountingpoints}</td>}
                  <td className='text-center'>{cell.depth} </td>
                  <td className='text-center'>{cell.width} </td>
                  <td className='text-center'>{cell.height} </td>
                  <td className='text-center'>{cell.weight} </td>
                  
                  <td className='text-center'>{cell.chemistry}</td>
                  <td className='text-center'>{cell.min_voltage}</td>
                  <td className='text-center'>{cell.nominal_voltage}</td>
                  <td className='text-center'>{cell.max_voltage}</td>
                  <td className='text-center'>{cell.c_rating_charge} </td>
                  <td className='text-center'>{cell.c_rating_discharge}</td>
                 {currentUser.type === 'admin' && <td className='text-center'>
                    {cell.cycle_life}
                    {cell.cycle_life_condition && 
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell.cycle_life_condition}</Tooltip>}>
                        <span className="d-inline-block">
                          <span class="material-symbols-outlined" style={{ pointerEvents: 'none' }}>help</span>
                        </span>
                      </OverlayTrigger>
                    }
                  </td>}
                  {currentUser.type === 'admin' && <td className='text-center'>{cell.cycle_promise_condition}</td>}
                  <td className='text-center'>{cell.energy_density} </td>
                  <td className='text-center'>{cell.specific_energy} </td>               
                  <td className='text-center'>
                    <ButtonGroup size='sm'>
                      <DropdownButton size="sm" variant="outline-secondary" as={ButtonGroup} title="Docs" id="bg-nested-dropdown">
                        {cell.datasheet_url && 
                          <Dropdown.Item download href={cell.datasheet_url} className="text-decoration-underline text-primary" target="_blank"> Datasheet</Dropdown.Item>
                        }
                        {cell.documents && cell.documents.map((document, index) => { return(
                          <Dropdown.Item key={index} download href={document.url} className="text-decoration-underline text-primary" target="_blank">
                            {document.filename}
                          </Dropdown.Item>
                        )})}                      
                      </DropdownButton>
                    </ButtonGroup>
                  </td>

                  <td>
                    <ButtonGroup size="sm">
                      <Button size='sm' variant="outline-secondary" onClick={e => openRfqModal(cell)}>
                        {!requestedQuotes.includes(cell.id) && 'Get Quote'}
                        {requestedQuotes.includes(cell.id) && <span className="material-symbols-outlined">done</span>}
                      </Button>
                      <Button size='sm' variant="outline-secondary" onClick={e => openShow(cell)}><span className="material-symbols-outlined">search</span></Button>
                      {currentUser.type === 'admin' &&
                      <Button size='sm' variant="outline-secondary" onClick={e => openModal('update', cell)}><span className="material-symbols-outlined">edit</span></Button>}
                      {currentUser.type === 'admin' &&
                      <Button size='sm' variant="outline-secondary" onClick={e => openModal('delete',cell)}><span className="material-symbols-outlined">delete</span></Button>
                      }
                    </ButtonGroup>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <CellShowModal showModal={displayShowModal} hideModal={hideModals} cell={currentCell} />
        <CellModal showModal={(currentAction === 'add' || currentAction === 'update') && displayModal} hideModal={hideModals}  handleCell={handleCell} cell={currentCell} currentAction={currentAction}/>
        <RequestQuoteModal showModal={displayRfqModal} hideModal={hideModals} getQuote={requestQuote} />
        <DeleteModal showModal={currentAction === 'delete' && displayModal} confirmModal={handleCell} hideModal={hideModals} element={currentCell} message={`Are you sure you want to delete Cell ${currentCell.model} ?`}/>      
      </Row>
    </Container>
  )
}

export default CellIndexComponent