export const SaveToLocalStorage = (payload) =>{
  try{
    const localStoragePayload = JSON.stringify(payload);
    localStorage.setItem('currentSession', localStoragePayload);
  }catch(error){
    console.log(error);
  }
}

export const LoadCurrentSession = () =>{
  try{
    const localStoragePayload = localStorage.getItem('currentSession');
    if(localStoragePayload == null){return null}
    return JSON.parse(localStoragePayload);
  }catch(error){
    console.log(error);
  }
}

export const DestroyCurrentSession = () =>{
  try {
    localStorage.removeItem('currentSession');
  } catch (error) {
    console.log(error);
  }
}