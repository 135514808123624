export const GetCompanies = (token) => {
  return async(dispatch) => {
    const companies = await fetch(`${process.env.REACT_APP_API_URL}/companies`, {
      method: 'Get',
      headers: {'Content-Type': 'application/json', token: token },
    })

    dispatch({
      type: 'GetCompanies', 
      payload: await companies.json()
    })
  }
}

export const AddCompany = (token, params) => {

  return async(dispatch) => {
    const companies = await fetch(`${process.env.REACT_APP_API_URL}/companies`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', token: token},
      body: JSON.stringify(params)
    })

    dispatch({
      type: 'AddCompany',
      payload: await companies.json()
    })
  }
}

export const UpdateCompany = (id, token, params) => {

  return async(dispatch) => {
    const companies = await fetch(`${process.env.REACT_APP_API_URL}/companies/${id}`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', token: token},
      body: JSON.stringify(params)
    })

    dispatch({
      type: 'UpdateCompany',
      payload: await companies.json()
    })
  }
}

export const DeleteCompany = (id, token) => {
  return async(dispatch) => {
    const companies = await fetch(`${process.env.REACT_APP_API_URL}/companies/${id}`, {
      method: 'DELETE',
      headers: {token: token}
    })

    dispatch({
      type: 'DeleteCompany',
      payload: await companies.json()
    })
  }
} 