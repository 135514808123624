const RfqReducer = (state = [], action) => {
  switch(action.type){
    case 'GetRfq':
      return state = action.payload;
    case 'SendRfq':
      return state = action.payload;
    case 'UpdateRfq':
      return state = action.payload;
    case 'DeleteRfq':
      return state = action.payload; 
    default: 
      return state;
  }
}

export default RfqReducer;