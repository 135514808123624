import { combineReducers } from "redux";
import MailsReducer from "./MailsReducer";
import SessionReducer from "./SessionReducer";
import CellsReducer from "./CellsReducer";
import RfqReducer from "./RfqReducer";
import UsersReducer from "./UsersReducer.js";
import CompaniesReducer from "./CompaniesReducer";

const allReducers = combineReducers({
  MailsReducer,
  SessionReducer,
  CellsReducer,
  RfqReducer,
  UsersReducer,
  CompaniesReducer
})

export default allReducers;