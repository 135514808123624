import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/widgets/NavbarComponent';
import RoutesController from './components/routes/RoutesController';
import {Row, Col, Container, Button, Form, Alert} from 'react-bootstrap'

function App() {
  return (
    <Container fluid className="p-0">
      <Row>
        <NavbarComponent/>
        <RoutesController/>
      </Row>    
    </Container>
  );
}

export default App;
