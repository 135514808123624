import React, {useState, useEffect} from 'react'
import { Modal, Button, Col, Row, Form } from 'react-bootstrap'


function UserModal({showModal, hideModal, handleUser, user, companies, type}) {
  const [data, setData] = useState({company_id: '', email: '', password: '', role: ''});

  useEffect(() => {
    if(!!user){
      const userData  = {company_id: user.company_id, email: user.email, password: '', role: user.role};
      setData(userData);
    }
  },[user])

  
  
  const closeModal = () => {
    closeModalData()
    hideModal();
  }

  const closeModalData = () => setData({company_id: '', email: '', password: '', role: ''});

  const handleData = (key, value) => {
    const userData = {...data};
    userData[key] = value;
    setData(userData);
  }

  const saveUser = () => {
    closeModalData()
    handleUser(data)
  }

  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{type} user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Role</Form.Label>
              <Form.Select value={!!data.role ? data.role : ''} onChange={e => handleData('role', e.target.value)}>
                <option value="">Select role</option>
                <option value="admin">Admin</option>
                <option value="customer">Customer</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Email</Form.Label>
              <Form.Control value={!!data.email ? data.email : ''} onChange={e => handleData('email', e.target.value)} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>Company</Form.Label>
              <Form.Select value={!!data.company_id ? data.company_id : ''} onChange={e => handleData('company_id', e.target.value)}>
                <option value="">Select company</option>
                {companies.map((company, index) => <option key={index} value={company.id}>{company.name}</option>)}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Password</Form.Label>
              <Form.Control value={!!data.password ? data.password : ''} onChange={e => handleData('password', e.target.value)} type='password'/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={e => closeModal()}>
            Cancel
          </Button>
          {<Button onClick={() => saveUser()}>
            {type} User
          </Button>}
        </Modal.Footer>
      </Modal>
  )
}

export default UserModal