import React, { useState, useEffect } from 'react'
import {Button, Modal, Row, Col, Form} from 'react-bootstrap' 

function CompanyModal({showModal, hideModal, handelCompany, company, modalType}) {
  const [data, setData] = useState({name: '', address: '', city: '', zip: '', country: '', phone: '', vat_number: ''})

  useEffect(() => {
    if(!!company){
      setData(company)
    }
  }, [company])

  const handleData = (key, value) => {
    const companyData = {...data};
    companyData[key] = value;
    setData(companyData);
  }

  const closeModal = () => {
    closeModalData();
    hideModal();
  }

  const closeModalData = () => {
    const companyData = {...data}
    for(let key of Object.keys(data)){
      companyData[key] = '';
    }
    setData(companyData)
  }

  const saveCompany = () => {
    closeModalData()
    handelCompany(data)
  }

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{`${modalType.charAt(0).toUpperCase() + modalType.slice(1)} company`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Label>Company name</Form.Label>
            <Form.Control value={!!data.name ? data.name : ''} onChange={e => handleData('name', e.target.value)} type='text'/>
          </Col>
          <Col>
            <Form.Label>Company contact person</Form.Label>
            <Form.Control value={!!data.contact_person ? data.contact_person : ''} onChange={e => handleData('contact_person', e.target.value)} type='text'/>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Company address</Form.Label>
            <Form.Control value={!!data.address ? data.address : ''} onChange={e => handleData('address', e.target.value)} type='text'/>
          </Col>
          <Col>
            <Form.Label>Company city</Form.Label>
            <Form.Control value={!!data.city ? data.city : ''} onChange={e => handleData('city', e.target.value)} type='text'/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <Form.Label>Company zip number</Form.Label>
            <Form.Control value={!!data.zip ? data.zip : ''} onChange={e => handleData('zip', e.target.value)} type='text'/>
          </Col>
          <Col>
            <Form.Label>Company country</Form.Label>
            <Form.Control value={!!data.country ? data.country : ''} onChange={e => handleData('country', e.target.value)} type='text'/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <Form.Label>Company phone</Form.Label>
            <Form.Control value={!!data.phone ? data.phone : ''} onChange={e => handleData('phone', e.target.value)} type='text'/>
          </Col>
          <Col>
            <Form.Label>Company vat number</Form.Label>
            <Form.Control value={!!data.vat_number ? data.vat_number : ''} onChange={e => handleData('vat_number', e.target.value)} type='text'/>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={e => closeModal()}>
          Cancel
        </Button>
        {<Button onClick={() => saveCompany()}>
          {`${modalType.charAt(0).toUpperCase() + modalType.slice(1)} company`}
        </Button>}
      </Modal.Footer>
    </Modal>
  )
}

export default CompanyModal