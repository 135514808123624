const CellsReducer = (state = [], action) => {
  switch(action.type){
    case 'GetCells':
      return state = action.payload.cells
    case 'AddCell':
      return state = action.payload
    case 'UpdateCell':      
      return state = action.payload
    case 'DeleteCell':
      return state = action.payload
    default: 
      return state;
  }
}

export default CellsReducer;