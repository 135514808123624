import React, {useState, useEffect} from 'react'
import { Container, Row, Table, Button, ButtonGroup, DropdownButton, Dropdown, Col } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'

import { GetCompanies, AddCompany, UpdateCompany, DeleteCompany } from '../../actions/CompaniesAction';
import CompanyModal from './CompanyModal';
import DeleteModal from '../widgets/modals/DeleteModal';

function CompanyIndexComponent() {
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const [modalType, setModalType] = useState('')
  const [currentCompany, setCurrentCompany] = useState({})
  const currentUser = useSelector(state => state.SessionReducer);
  const companies = useSelector(state => state.CompaniesReducer);

  useEffect(() => {
    getCompanies()
  },[])

  const getCompanies = () => dispatch(GetCompanies(currentUser.token));
  
  const hideModals = () => {
    setDisplayModal(false);
    setModalType('')
  }

  const openModal = (action, company) => {
    if(action === 'new'){setCurrentCompany({})}
    if(action === 'update' || action === 'delete'){setCurrentCompany(company)}
    setDisplayModal(true);
    setModalType(action);
  }

  const handelCompany = (company) => {
    if(modalType === 'new'){dispatch(AddCompany(currentUser.token, company))}
    if(modalType === 'update'){dispatch(UpdateCompany(currentCompany.id, currentUser.token, company))}
    if(modalType === 'delete'){dispatch(DeleteCompany(currentCompany.id, currentUser.token))}
    hideModals();
    setModalType('');
  }

  return (
    <Container fluid className="px-5">
      <Row className="my-3 mt-5">
        <Col>
        {currentUser.type === 'admin' && 
          <div>
            <Button onClick={e => openModal('new', null)}>Add Company</Button>
            <br />
            <br />
          </div>
        }
      </Col>
      </Row>
      
      <Row className="justify-content-center">
      <Col sm="6">
        
      
      <Table size="sm" variant="tight" className="pb-5">
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact person</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Vat</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company ,index) => {return(
            <tr key={index}>
              <td>{company.name}</td>
              <td>{company.contact_person}</td>
              <td className="text-tight">
                <p>{company.address}</p>
                <p>{company.city}</p>
                <p>{company.zip}</p>
                <p>{company.country}</p>
              </td>
              <td>{company.phone}</td>
              <td>{company.vat_number}</td>
              <td>
                {currentUser.type === 'admin' &&                   
                  <ButtonGroup size="sm">            
                    {currentUser.type === 'admin' &&
                    <Button variant="outline-secondary" onClick={e => openModal('update', company)}><span className="material-symbols-outlined">edit</span></Button>}
                    {currentUser.type === 'admin' &&
                    <Button variant="outline-secondary" onClick={e => openModal('delete',company)}><span className="material-symbols-outlined">delete</span></Button>
                    }
                  </ButtonGroup>
                }
              </td>
            </tr>
          )})}
        </tbody>
      </Table>
      <CompanyModal showModal={(modalType === 'update' || modalType === 'new') && displayModal} hideModal={hideModals}  handelCompany={handelCompany} company={currentCompany} modalType={modalType}/>
      <DeleteModal showModal={modalType === 'delete' && displayModal} confirmModal={handelCompany} hideModal={hideModals} element={currentCompany} message={`Are you sure you want to delete company ${currentCompany.name} ?`}/>
      </Col>
      </Row>
    </Container>

  )
}

export default CompanyIndexComponent