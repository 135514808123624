import React, {useState} from 'react'
import { Modal, Button, Col, Row, Form, Alert } from 'react-bootstrap'

function RequestQuoteModal({showModal, hideModal, getQuote}) {
  const [quantity , setQuantity] = useState(0)
  const [canBePooled, setCanBePooled] = useState(false)
  const [deliveryDateAndAmmounts, setDeliveryDateAndAmmounts] = useState('')
  const [requestedModifications, setRequestedModifications] = useState('')
  const [note, setNote] = useState('')  
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [lastDateForQuotation, setLastDateForQuotation] = useState('');
  const [incoTerms, setIncoTerms] = useState('EXW factory')
  
  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Request quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label className='d-flex'>Quantity <p style={{color: 'red'}}> *</p></Form.Label>
              <Form.Control value={quantity} onChange={e => setQuantity(e.target.value)} type='number' />            
            </Col>
            <Col>
              <Form.Label>Can be pooled </Form.Label>
              <Form.Check value={canBePooled} onChange={e => setCanBePooled(e.target.value)} type='checkbox' />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>Delivery dates & amounts</Form.Label>
              <Form.Control value={deliveryDateAndAmmounts} onChange={e => setDeliveryDateAndAmmounts(e.target.value)} type='text' as='textarea' />
            </Col>
            <Col>
              <Form.Label>Modifications</Form.Label>
              <Form.Control value={requestedModifications} onChange={e => setRequestedModifications(e.target.value)} type='text' as='textarea' />             
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>INCO terms</Form.Label>
              <Form.Select value={incoTerms} onChange={e => setIncoTerms(e.target.value)}>
                <option value='EXW factory'>EXW factory</option>
                <option value='FOB Yantian/Hong kong'>FOB Yantian/Hong kong</option>
                <option value='DAP'>DAP</option>
                <option value='FCA'>FCA</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Last date for quotation</Form.Label>
              <Form.Control value={lastDateForQuotation} onChange={e => setLastDateForQuotation(e.target.value)} type='date' />
            </Col>
          </Row>

          <br />
          <Row>
            <Col>
              <Form.Label>Notes</Form.Label>
              <Form.Control value={note} onChange={e => setNote(e.target.value)} type='text' as='textarea' />
            </Col>
            <Col>
              <Form.Label>Delivery address</Form.Label>
              <Form.Control value={deliveryAddress} onChange={e => setDeliveryAddress(e.target.value)} type='text' as='textarea' />
            </Col>
          </Row>
          {(quantity === 0 || quantity === '0') && <Alert variant='danger'>Quantity cannot be 0 </Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          <Button disabled={quantity === 0 || quantity === '0'} onClick={() => getQuote({quantity, canBePooled, deliveryDateAndAmmounts, requestedModifications, lastDateForQuotation, deliveryAddress, note, incoTerms})}>
            Request quote
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default RequestQuoteModal