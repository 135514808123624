import React, { useEffect, useState } from 'react';
import { Container, Row, Table, Button, ButtonGroup, DropdownButton, Dropdown, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router';
import { GetRfq, UpdateRfq, DeleteRfq} from '../../actions/RfqAction';
import {GetCompanies} from '../../actions/CompaniesAction';
import {GetUsers} from '../../actions/UsersAction';
import {GetCells} from '../../actions/CellsAction';
import UpdateQuoteModal from './UpdateQuoteModal';
import DeleteModal from '../widgets/modals/DeleteModal';
import CellShowModal from '../cell/CellShowModal';

function RequestQuoteIndexComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayUpdateQuoteModal, setDisplayUpdateQuoteModal] = useState(false)
  const [dispalyDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayCellShowModal, setDisplayCellShowModal] = useState(false);
  const [currentCell,  setCurrentCell] = useState({})
  const [currentQuote, setCurrentQuote] = useState({});
  const currentUser = useSelector(state => state.SessionReducer);
  const rfqs = useSelector(state => state.RfqReducer);
  const companies = useSelector(state => state.CompaniesReducer);
  const users = useSelector(state => state.UsersReducer);
  const cells = useSelector(state => state.CellsReducer);
  

  useEffect(() => {
    getData();
  },[])

  const getData = () => {
    dispatch(GetRfq(currentUser.token));
    dispatch(GetUsers(currentUser.token));
    dispatch(GetCompanies(currentUser.token));
    dispatch(GetCells({}, currentUser.token));
  }

  const hideModals = () => {
    setDisplayUpdateQuoteModal(false);
    setDisplayDeleteModal(false);
    setDisplayCellShowModal(false);
    setCurrentCell({});
  }

  const openUpdateQuote = (quote) => {
    setCurrentQuote(quote);
    setDisplayUpdateQuoteModal(true)
  }

  const updateQuote = (quote) => {    
    if(!quote.quote.type){delete quote.quote}
    if(!quote.purchase_order.type){delete quote.purchase_order}
    if(!quote.order_confirmation.type){delete quote.order_confirmation}
    if(!quote.cellreport.type){delete quote.cellreport}
    if(!quote.bill_of_lading.type){delete quote.bill_of_lading}
    const formData = new FormData();
    for(let key of Object.keys(quote)){      
      formData.append(`rfq[${key}]`, quote[key]);
    }
    dispatch(UpdateRfq(currentQuote.id, currentUser.token, formData));
    hideModals();
  }
  
  const openDeleteModal = (rfq) => {
    setCurrentQuote(rfq);
    setDisplayDeleteModal(true);
  }

  const deleteConfirmedRfq = (rfq) => {
    dispatch(DeleteRfq(rfq.id, currentUser.token));
    hideModals();
  }

  const showCompany = (id) =>{
    for(let company  of companies){
      if(company.id === id){
        return company.name
      }
    }
  } 

  const showUser = (id) => {
    for(let user of users){
      if(user.id === id){
        return user.email
      }
    }
  }

  const showCells = (id) => {
    for(let cell of cells){
      if(cell.id === id){
        return cell.model
      }
    }
  }

  const openCell = (id) => {
    for(let cell of cells){
      if(cell.id === id){
        setCurrentCell(cell)
        break
      }
    }
    setDisplayCellShowModal(true);
  }

  return (
    <Container fluid className="px-5">
      <Row className="my-3 mt-5">
        <Col>     

      <div>
        <Button onClick={e => navigate('/cells', {})}>New RFQ</Button>
      </div>
              </Col>
      </Row>
      <Row>
      <Table hover size="sm" variant="tight" className="pb-5">
        <thead>
          <tr>
            <th>ID</th>
            <th>Cell</th>
            <th>Company</th>
            <th>Creator</th>
            <th>Qty</th>
            <th>Status</th>
            <th>Note</th>
            <th>Delivery schedule</th>
            <th>Modifications</th>
            <th>INCO terms</th>
            <th>Pooled</th>
            <th>Delivery address</th>
            <th>Quote deadline</th>
            <th>Quote</th>
            <th>PO</th>
            <th>OC</th>
            <th>Cell report</th>
            <th>B/L</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rfqs.map((rfq, index) => {return(
            <tr key={index}>
              <td>{rfq.id}</td>
              <td className='pointer' onClick={e => openCell(rfq.cell_id)} style={{color: 'blue'}}>{showCells(rfq.cell_id)}</td>
              <td>{showCompany(rfq.company_id)}</td>
              <td>{showUser(rfq.created_by)}</td>
              <td>{rfq.quantity}</td>
              <td>{rfq.status}</td>
              <td>{rfq.note}</td>
              <td>{rfq.delivery_dates_and_amounts}</td>
              <td>{rfq.requested_modifications}</td>
              <td>{rfq.inco_terms}</td>
              <td>{rfq.can_be_pooled ? 'yes' : 'no'}</td>
              <td>{rfq.delivery_address}</td>
              <td>{rfq.last_date_for_quotation}</td>
              <td>                
                {!!rfq.quote_url ? <Button variant="outline-secondary" size="sm" href={rfq.quote_url} target='_blank' rel="noreferrer">Download</Button> : ''}
              </td>
              <td>                
                {!!rfq.purchase_order_url ? <Button variant="outline-secondary" size="sm" href={rfq.purchase_order_url} target='_blank' rel="noreferrer">Download</Button> : ''}
              </td>
              <td>                
                {!!rfq.order_confirmation_url ? <Button variant="outline-secondary" size="sm" href={rfq.order_confirmation_url} target='_blank' rel="noreferrer">Download</Button> : ''}
              </td>
              <td>                
                {!!rfq.cellreport_url ? <Button variant="outline-secondary" size="sm" href={rfq.cellreport_url} target='_blank' rel="noreferrer">Download</Button> : ''}
              </td>
              <td>
                {!!rfq.bill_of_lading_url ? <Button variant="outline-secondary" size="sm" href={rfq.bill_of_lading_url} target='_blank' rel="noreferrer">Download</Button> : '' }
              </td>
              <td>
              
              <ButtonGroup size="sm">
                <Button variant="outline-secondary" onClick={e => openUpdateQuote(rfq)}><span className="material-symbols-outlined">edit</span></Button>
                {currentUser.type === 'admin' &&
                <Button variant="outline-secondary" onClick={e => openDeleteModal(rfq)}><span className="material-symbols-outlined">delete</span></Button>
                }
              </ButtonGroup>
                
              </td>
            </tr>
          )})}
        </tbody>
      </Table>
      <CellShowModal showModal={displayCellShowModal} hideModal={hideModals} cell={currentCell} />
      <UpdateQuoteModal showModal={displayUpdateQuoteModal} hideModal={hideModals} updateQuote={updateQuote} quote={currentQuote}/>
      <DeleteModal showModal={dispalyDeleteModal} confirmModal={deleteConfirmedRfq} hideModal={hideModals} element={currentQuote} message={`Are you sure you want to delete  quote request?`}/>
    </Row>
    </Container>
  )
}

export default RequestQuoteIndexComponent