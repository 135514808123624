import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Button, Col, Row, Form } from 'react-bootstrap'

function UpdateQuoteModal({ showModal, hideModal, updateQuote, quote }) {
  const [quantity, setQuantity] = useState(0);
  const [canBePooled, setCanBePooled] = useState(false);
  const [deliveryDatesAndAmounts, setDeliveryDatesAndAmounts] = useState('');
  const [lastDateForQuotation, setLastDateForQuotation] = useState('');
  const [requestedModifications, setRequestedModifications] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [note, setNote] = useState('');
  const [orderQuote, setQuote] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({})
  const [orderConfirmation, setOrderConfirmation] = useState({});
  const [billOfLading, setBillOfLading] = useState({});
  const [cellreport, setCellreport] = useState({});
  const [status, setStatus] = useState('');
  const [incoTerms, setIncoTerms] = useState('');
  const currentUser = useSelector(state => state.SessionReducer);

  useEffect(() => {
    console.log(quote)
    setQuantity(!!quote.quantity ? quote.quantity : '');
    setCanBePooled(!!quote.can_be_pooled ? quote.can_be_pooled : false);
    setLastDateForQuotation(!!quote.last_date_for_quotation ? quote.last_date_for_quotation : '');
    setDeliveryDatesAndAmounts(!!quote.delivery_dates_and_amounts ? quote.delivery_dates_and_amounts : '');
    setRequestedModifications(!!quote.requested_modifications ? quote.requested_modifications : '');
    setDeliveryAddress(!!quote.delivery_address ? quote.delivery_address : '');
    setNote(!!quote.note ? quote.note : '');
    setStatus(!!quote.status ? quote.status : '');
    setIncoTerms(!!quote.inco_terms ? quote.inco_terms : '');
  }, [quote])

  const canEdit = () => {
    //if(currentUser.type === 'admin'){ return false}
    if (!!quote.quote || !!quote.purchase_order || quote.order_confirmation || quote.confirmed_delivery_date) { return true }
  }

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Label>Quantity</Form.Label>
            <Form.Control value={quantity} onChange={e => setQuantity(e.target.value)} type='number' disabled={canEdit()} />
          </Col>
          <Col>
            <Form.Label>Can be pooled </Form.Label>
            <Form.Check value={canBePooled} onChange={e => setCanBePooled(!canBePooled)} type='checkbox' disabled={canEdit()} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Delivery dates and amounts</Form.Label>
            <Form.Control value={deliveryDatesAndAmounts} onChange={e => setDeliveryDatesAndAmounts(e.target.value)} type='text' as='textarea' disabled={canEdit()} />
          </Col>
          <Col>
            <Form.Label>Last date for quotation</Form.Label>
            <Form.Control value={requestedModifications} onChange={e => setRequestedModifications(e.target.value)} type='date' disabled={canEdit()} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Delivery address</Form.Label>
            <Form.Control value={deliveryAddress} onChange={e => setDeliveryAddress(e.target.value)} type='text' as='textarea' disabled={canEdit()} />
          </Col>
          <Col>
            <Form.Label><span className="material-symbols-outlined">edit</span> Note</Form.Label>
            <Form.Control value={note} onChange={e => setNote(e.target.value)} type='text' as='textarea' />
          </Col>
        </Row>
        <br />
        <Row>
          {currentUser.type === 'admin' &&
            <Col>
              <Form.Label>Status</Form.Label>
              <Form.Select value={status} onChange={e => setStatus(e.target.value)}>
                <option value=''>Select status</option>
                <option value='Waiting for quote'>Waiting for quote</option>
                <option value='Waiting for purchase order'>Waiting for purchase order</option>
                <option value='Waiting for order confirmation'>Waiting for order confirmation</option>
                <option value='Confirmed'>Confirmed</option>
                <option value="In transit">In transit</option>
                <option value="Completed">Completed</option>
              </Form.Select>
            </Col>
          }

          <Col>
            <Form.Label>INCO terms</Form.Label>
            <Form.Select value={incoTerms} onChange={e => setIncoTerms(e.target.value)}>
              <option value=''>Select term</option>
              <option value='EXW factory'>EXW factory</option>
              <option value='FOB Yantian/Hong kong'>FOB Yantian/Hong kong</option>
              <option value='DAP'>DAP</option>
              <option value='FCA'>FCA</option>
            </Form.Select>
          </Col>
        </Row>
 
        {currentUser.type === 'admin' &&
          <Row>
            <Col>
              <Form.Label>Quote</Form.Label>
              <Form.Control onChange={e => setQuote(e.target.files[0])} type='file' />
            </Col>
            <Col>{quote.quote}</Col>
          </Row>
        }

        {(currentUser.type === 'admin' || (currentUser.type === 'customer' && quote.quote)) &&
          <Row>
            <Col>
              <Form.Label>Purchase order</Form.Label>
              <Form.Control onChange={e => setPurchaseOrder(e.target.files[0])} type='file' />
            </Col>
            <Col>{quote.purchase_order}</Col>
          </Row>
        }

        {currentUser.type === 'admin' &&
          <Row>
            <Col>
              <Form.Label>Cell report</Form.Label>
              <Form.Control onChange={e => setCellreport(e.target.files[0])} type='file' />
            </Col>
            <Col>{quote.cellreport}</Col>
          </Row>
        }
        {currentUser.type === 'admin' &&
          <Row>
            <Col>
              <Form.Label>Order confirmation</Form.Label>
              <Form.Control onChange={e => setOrderConfirmation(e.target.files[0])} type='file' />
            </Col>
            <Col>{quote.order_confirmation}</Col>
          </Row>
        }
        {currentUser.type === 'admin' &&
          <Row>
            <Col>
              <Form.Label>Bill of lading</Form.Label>
              <Form.Control onChange={e => setBillOfLading(e.target.files[0])} type='file' />
            </Col>
            <Col>{quote.order_confirmation}</Col>
          </Row>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={() => updateQuote({ quantity, can_be_pooled: canBePooled, last_date_for_quotation: lastDateForQuotation, delivery_dates_and_amounts: deliveryDatesAndAmounts, delivery_address: deliveryAddress, requested_modifications: requestedModifications, note, quote: orderQuote, order_confirmation: orderConfirmation, purchase_order: purchaseOrder, cellreport, status, inco_terms: incoTerms, bill_of_lading: billOfLading })}>
          Update quote
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateQuoteModal