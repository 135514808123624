const UsersReducer = (state = [], action) => {
  switch(action.type){
    case 'GetUsers':
      return state = action.payload;
    case 'AddUser':
      return state = action.payload;
    case 'UpdateUser':
      return state = action.payload;
    case 'DeleteUser':
      return state = action.payload; 
    default: 
      return state;
  }
}

export default UsersReducer;