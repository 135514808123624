import {Routes, Route} from 'react-router-dom';
import LoginComponent from '../LoginComponent';
import CellIndexComponent from '../cell/CellIndexComponent';
import RequestQuoteIndexComponent from '../rfq/RequestQuoteIndexComponent';
import UsersIndexComponent from '../user/UsersIndexComponent';
import CompanyIndexComponent from '../company/CompanyIndexComponent';


import SessionRoutes from './SessionRoutes';
import AdminRoutes from './AdminRoutes';


function RoutesController() {
  return (
    <Routes>
      <Route path='/login' exact element={<LoginComponent/>} />
      <Route path='/' exact element={<LoginComponent/>} />


      <Route element={<SessionRoutes/>}>
        <Route path='/cells' exact element={<CellIndexComponent/>} />
        <Route path='/rfqs' exact element={<RequestQuoteIndexComponent/>} />
        <Route element={<AdminRoutes/>} >
          <Route path='/users' exact element={<UsersIndexComponent/>} />
          <Route path='/companies' exact element={<CompanyIndexComponent/>} />
        </Route>
      </Route>
    </Routes>
  )
}

export default RoutesController