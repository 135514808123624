export const GetRfq = (token ) => {
  return async(dispatch) => {
    const rfqRequest = await fetch(`${process.env.REACT_APP_API_URL}/rfqs`, {
      method: 'Get',
      headers: {"Content-Type": "application/json", token: token},
    })

    dispatch({
      type: 'GetRfq',
      payload: await rfqRequest.json()
    })
  }
}

export const UpdateRfq = (id, token, formData) => {

  return async(dispatch) => {
    const rfqs = await fetch(`${process.env.REACT_APP_API_URL}/rfqs/${id}`, {
      method: 'PUT',
      headers: {token: token},
      body: formData
    })

    dispatch({
      type: 'UpdateRfq',
      payload: await rfqs.json()
    })
  }
}

export const SendRfq = (rfq, token ) => {
  return async(dispatch) => {
    const rfqRequest = await fetch(`${process.env.REACT_APP_API_URL}/rfqs`, {
      method: 'POST',
      headers: {"Content-Type": "application/json", token: token},
      body: JSON.stringify(rfq)
    })

    dispatch({
      type: 'SendRfq',
      payload: await rfqRequest.json()
    })
  }
}

export const DeleteRfq = (id, token ) => {
  return async(dispatch) => {
    const rfqRequest = await fetch(`${process.env.REACT_APP_API_URL}/rfqs/${id}`, {
      method: 'DELETE',
      headers: {"Content-Type": "application/json", token: token},
    })

    dispatch({
      type: 'DeleteRfq',
      payload: await rfqRequest.json()
    })
  }
}